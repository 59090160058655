<template>
    <section class="mx-auto">
        <Breadcrumb
            :links="[
                {
                    label: `<  Student Outputs`,
                    link: true, 
                    route: (prevRoute && prevRoute.name === `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Student Outputs`) ? { name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Student Outputs`, query: { ...prevRoute.query} } : { name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Student Outputs`, query: { search: '', page: 1, paginate: 10, classes: '' } },
                },
                {
                    label: `${title}`,
                    link: false,
                    route: null,
                },
            ]"
        />
        <section class="d-flex flex-wrap justify-space-between">
            <SearchBar 
                :placeholder="'Search student'"
                :value.sync="search" 
                class='mb-3' 
                @clear="clearSearch"
                @search="onSearch"/>

            <ButtonExport
                @click="exportEnrollees"
                :loading="exportLoading"
                :disabled="loading || enrollees.length === 0"
                class="ma-1 ml-auto mb-3"/>
        </section>

        <v-data-table
            :headers="enrollees_tbl"
            :items="enrollees"
            class="elevation-1 poppins"
            :loading="loading"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{
                'items-per-page-options': itemsPerPageOptions
            }"
            :page="page"
            :server-items-length="totalCount"
            @pagination="(e) => {
                page = e.page
                paginate = String(e.itemsPerPage),
                updateQuery()
            }"
        >
            <template v-slot:item.action="{ item }">
                <v-btn block dense color="primary" text class="f12"  
                    @click="$router.push({name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Student Outputs Enrollee Progress`, params: {course_uuid: $route.params.course_uuid, user_id: item.id},  query: { search: '', page: 1, paginate: 10 }})">
                    <v-icon class="mr-1" dense>
                        mdi-eye-outline
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        
        <!-- <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginationPaginate"
            @page="onPageChange" 
            @paginate="onPaginateChange"/> -->

    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';
import Breadcrumb from "@/layouts/teacher/InstructorBreadcrumbs.vue";
import { enrollees_tbl } from "@/constants/tblheaders/studentprogress"

export default {
    mixins: [searchAndPaginateMixin],
    components: {
        Breadcrumb
    },
    data: () => ({
        title: '',
        loading: false,
        exportLoading: false,
        enrollees_tbl,
        pageCount: 1,
        prevRoute: null,
        totalCount: 0,
        itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
    }),

    computed: {
        ...mapState('instructor', {
            instructorEnrollees: (state) => state.enrollees
        }),

        ...mapState('admin', {
            adminEnrollees: (state) => state.enrollees
        }),

        ...mapState({
            user: (state) => state.user
        }),

        itemsPerPage(){
            return Number(this.paginate)
        },

        paginationPaginate(){
            return String(this.paginate)
        },

        enrollees() {
            if (!this.user) return [];

            const roleEnrolleesMap = {
                INSTRUCTOR: this.instructorEnrollees,
                ADMIN: this.adminEnrollees,
            };

            return roleEnrolleesMap[this.user.role] || [];
        }

    },

    methods: {
        ...mapActions(
            "instructor", 
                { 
                    getInstructorStudentOutputEnrolleesAction: "getStudentOutputEnrolleesAction", 
                    getInstructorExportAction: "enrolleesExportAction" 
                }
        ),

        ...mapActions(
            "admin", 
                { 
                    getAdminStudentOutputEnrolleesAction: "getStudentOutputEnrolleesAction", 
                    getAdminExportAction: "enrolleesExportAction" 
                }
        ),

        ...mapMutations(['alertMutation']),

        getData() {
            if(!this.loading) {
                if(this.user.role === 'INSTRUCTOR') {
                    this.loading = true
                    this.getInstructorStudentOutputEnrolleesAction({ course_uuid: this.$route.params.course_uuid, paginate: Number(this.paginate), page: this.page, search: this.search }).then(res => {
                        this.title = res.title
                        this.page = res.current_page
                        this.pageCount = res.last_page
                        this.paginate = String(res.per_page)
                        this.totalCount = res.total
                        this.loading = false
                    }).catch(() => {
                        this.loading = false
                    })
                } else if(this.user.role === 'ADMIN') {
                    this.loading = true
                    this.getAdminStudentOutputEnrolleesAction({ course_uuid: this.$route.params.course_uuid, paginate: Number(this.paginate), page: this.page, search: this.search }).then(res => {
                        this.title = res.title
                        this.page = res.current_page
                        this.pageCount = res.last_page
                        this.paginate = String(res.per_page)
                        this.totalCount = res.total
                        this.loading = false
                    }).catch(() => {
                        this.loading = false
                    })
                } 
            }
        },

        exportEnrollees(){
            if(this.user.role === 'INSTRUCTOR') {
                this.exportLoading = true
                this.getInstructorExportAction({ course_uuid: this.$route.params.course_uuid, search: this.search}).then(res => {
                    this.exportLoading = false
                    this.$exportToCSV(`${this.title} - enrollees`, res)
                }).catch(e => {
                    this.exportLoading = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                })
            } else if(this.user.role === 'ADMIN') {
                this.exportLoading = true
                this.getAdminExportAction({ course_uuid: this.$route.params.course_uuid, search: this.search}).then(res => {
                    this.exportLoading = false
                    this.$exportToCSV(`${this.title} - enrollees`, res)
                }).catch(e => {
                    this.exportLoading = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                })
            } 
        }
    },
  
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
}
</script>